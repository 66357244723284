// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-emigration-index-js": () => import("./../src/pages/emigration/index.js" /* webpackChunkName: "component---src-pages-emigration-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-living-index-js": () => import("./../src/pages/living/index.js" /* webpackChunkName: "component---src-pages-living-index-js" */),
  "component---src-pages-news-index-js": () => import("./../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-shelter-index-js": () => import("./../src/pages/shelter/index.js" /* webpackChunkName: "component---src-pages-shelter-index-js" */),
  "component---src-templates-emigration-js": () => import("./../src/templates/emigration.js" /* webpackChunkName: "component---src-templates-emigration-js" */)
}

